import React, { useEffect } from 'react'
import Hero from '../global/Hero'

function Home() {

  return (
    <div>
      <Hero />
    </div>
  )
}

export default Home